import React from "react";

const Hero = () => {
    return (
        <div className="hero-background-image ">
        <div className="h-[800px] hero-padding-color ">  
            <div className="hero-box align-middle"> 
                    <h2>Welcome</h2> 
                    <h4>&lt;--  To --&gt;</h4>
                    <h3>Cape Code Beaches</h3>
                    <div className="hero-box-p-body">
                    <p>
                    The plan is, you NEED to go to the beach! Cape Cod has the best beaches around! 
                    If you want to find out where to go, we can give you some details about that area. 
                    Check out our map to find out what's close,
                    or where you want to go along the Cape. You may want to have a quick, shower afterward, 
                    you don't have to, but some beaches have showers. If you'd like to bring an ice bag, 
                    with drinks and lunch, that sounds great. Some have a restaurant on the beach, 
                    you can do that instead. We'll let you know which ones have it! For me, 
                    I would grab an ice cream if they have them. It might be hard to find a spot to park 
                    when it's busy. Cape Cod Beaches App can find out what's open, or when it might be available.
                    </p>
                    <p className="text-center hero-box-closing-p ">
                    <br/>
                    Our plan, is no plan, but YOUR GREAT PLANS! 
                    </p>
                    </div>
                </div>
            <div>
            </div>
        </div></div>
    );
}

export default Hero;