import React, { useState } from "react";
import CarouselItem from "./CarouselItem";
const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [sizePage, setSizePage] = useState(0);
    
    const items = [
{
    title: `Barnstable`,
    description: `Barnstable`,
    icon: "town-seal/barnstable.png"
},
{
    title: `Bourne`,
    description: `Bourne`,
    icon: "town-seal/bourne.png"
},
{
    title: `Brewster`,
    description: `Brewster`,
    icon: "town-seal/brewster.png"
},
{
    title: `Chatham`,
    description: `Chatham`,
    icon: "town-seal/chatham.png"
},
{
    title: `Dennis`,
    description: `Town Of Dennis`,
    icon: "town-seal/dennis.png"
},
{
    title: `Eastham`,
    description: `Eastham`,
    icon: "town-seal/eastham.png"
},
{
    title: `Falmouth`,
    description: `Falmouth`,
    icon: "town-seal/falmouth.png"
},
{
    title: `Harwich`,
    description: `Harwich`,
    icon: "town-seal/harwich.jpg"
},
{
    title: `Mashpee`,
    description: `Mashpee`,
    icon: "town-seal/mashpee.png"
},
{
    title: `Orleans`,
    description: `Orleans`,
    icon: "town-seal/orleans.png"
},
{
    title: `Provincetown`,
    description: `Provincetown`,
    icon: "town-seal/provincetown.png"
},
{
    title: `Truro`,
    description: `Truro`,
    icon: "town-seal/truro.png"
},
{
    title: `Wealfleet`,
    description: `Wellfleet`,
    icon: "town-seal/wellfleet.png"
},
{
    title: `Yarmouth`,
    description: `Yarmouth`,
    icon: "town-seal/yarmouth.png"
},



    ]; 
    const updateIndex = (nextIndex) => {
        var w = window.innerWidth;
        setSizePage(w);
        if( nextIndex < 0) {
            nextIndex = 0;
        } else if (nextIndex >= items.length) {
            nextIndex = 0;
        }
        setActiveIndex(nextIndex);
    };
    return (
        <div className="carousel">
            <div
                className="inner"
                style={{ transform: `translate(-${activeIndex * 100}%)`
                }}>
                {items.map((item) => {
                    return <CarouselItem item={item} width={"100%"} />;
                 })}
            </div>

            <div className="carousel-buttons ">
                    <div className="bottomnav-column">
                        <button 
                            className="button-arrow" 
                            onClick={() =>{
                            updateIndex(activeIndex - 1)
                         }} >
                            <span className="carousel-button-left"></span> 
                        </button>
                    </div>
                    <div className="bottomnav-column">
                        <div className="indicators">
                        </div>
                    </div>
                    <div className="bottomnav-column">
                        <button  onClick={() =>{
                                    updateIndex(activeIndex + 1)
                            }} 
                            className="button-arrow">
                            <span className="carousel-button-right"></span> 
                        </button>
                    </div>
                </div>

        </div>
    );
}

export default Carousel;