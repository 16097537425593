import React, {useEffect} from "react";

const Error = ({page_size}) => {

    return (
    <>
     <div className="error-page-background-image">
        <div className="h-[800px] hero-padding-color ">  
            <a href="/">
                <div className="hero-box align-middle"> 
                    <h2>Woops!!!</h2> 
                    <h4>&lt;--  Time To Go Home --&gt;</h4>
                    <h4>TO</h4>
                    <h3>Cape Code Beaches App</h3>
                </div>
            </a>
        </div>
    </div>     
    </>
    );
}
export default Error; 
