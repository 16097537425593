import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopNav from './Component/TopNav';
import Landing from './Page/Landing';
import Error from './Page/Error';
import BottomNav from './Component/BottomNav';
import './fonts/ShadowsIntoLight-Regular.ttf';

function App() {
  return (
   <BrowserRouter>

  <div>
        <TopNav/>
        <Routes>
           <Route path="/" element={<Landing/>} />
           <Route path="/*" element={<Error/>} />
        </Routes>

        <BottomNav/>
  </div>
  </BrowserRouter>
  );
}

export default App;
