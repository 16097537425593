import React, {useEffect} from "react";
import { Loader} from "@googlemaps/js-api-loader";
import umbrella from "../logo192.png";

const BeachMaps = ({page_size}) => {

    useEffect(() => { 

      const pageWidth = document.documentElement.clientWidth
       let mapCenter = {lat:41.809 , lng:-70.3000 };
       let zoomin = 11;
       if(pageWidth < 420){
          zoomin = 12;
          mapCenter = {lat:41.670 , lng:-70.3000 };
       } else if(pageWidth < 680){
          mapCenter = {lat:41.7090 , lng:-70.3000 };
       } 

       fetch("https://o8bes3ddv2.execute-api.us-east-1.amazonaws.com/staging/beach", {
            "method": "GET",
        })
        .then(response => response.json())
        .then(response => {
            const mapOptions = {
                center: mapCenter, // Set the initial center of the map
                zoom: zoomin, // Set the initial zoom level
                streetViewControl: false,
                mapTypeControl: false,

            };

            const loader = new Loader({
                apiKey: "AIzaSyCIAXaRBlA1a4A-A9GKY60-xFMz3334iGU",
                version: "3.55",
                libraries: ['maps'],

            });



            const capeTowns = [
              {name: 'Dennis', lat:41.7028695, lng:-70.1505926, description:'Dennis Mass' },
              {name: 'Yarmouth', lat:41.71, lng:-70.23, description:'Yarmouth Mass' },
              {name: 'Harwich', lat:41.676, lng:-70.06, description:'Harwich Mass' },
              {name: 'Chatham', lat:41.687, lng:-69.96, description:'Harwich Mass' },
              {name: 'Brewster', lat:41.764, lng:-70.08, description:'Brewster Mass' },
              {name: 'Orleans', lat:41.792, lng:-69.99, description:'Orleans Mass' },
              {name: 'Wellfleet', lat:41.935, lng:-70.0299705, description:'Wellfleet Mass' },
              {name: 'Touro', lat:42.0061905, lng:-70.05418, description:'Touro Mass' },
              {name: 'Provincetown', lat:42.0563071, lng:-70.1860, description:'Provincetown Mass' },
              {name: 'Eastham', lat:41.833, lng:-69.974, description:'Eastham Mass' },
              {name: 'Barnstable', lat:41.702, lng:-70.3021284, description:'Barnstable Mass' },
              {name: 'Mashpee', lat:41.652, lng:-70.479, description:'Mashpee Mass' },
              {name: 'Bourne', lat:41.744, lng:-70.6, description:'Burne Mass' },
              {name: 'Sandwich', lat:41.761, lng:-70.49, description:'Sandwich Mass' },
              {name: 'Falmouth', lat:41.556245, lng:-70.6099955, description:'Mashpee Mass' },
            ];


            //console.log(loader.importLibrary('maps'));
            loader.importLibrary('maps')
                .then(({Map, StyledMapType, InfoWindow}) => {
                    //Section includes styles 
                    const mapStyle = new StyledMapType([
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [{ "visibility": "off" }]
                        }
                    ], { name: "beachtype" });
                    //This will set the div id="map" now
                    const beachesMap = new Map(document.getElementById("beachesmap"), mapOptions);
                    beachesMap.mapTypes.set('beachtype', mapStyle);
                    beachesMap.setMapTypeId("beachtype");

                    var beachrow = -1;
                    //Add markers from the above new Map added 
                    loader.importLibrary("marker")
                      .then(({Marker})=> {
                        response.forEach((beach) => {
                            //Adding points for each area
                            if(beachrow < capeTowns.length ){
                              beachrow = beachrow + 1
                              const county = capeTowns[beachrow];
                              const beachPosition =  {lat:county.lat, lng:county.lng, };
                              const marker = new Marker({
                                    position: beachPosition,
                                    optimized: true,
                                    beachesMap,
                              })

                                  const tooltipContent = `<a href=\"/${county.name}\">${county.name}</a>`;
                                  const infoWindow = new InfoWindow({
                                    content: tooltipContent,
                                  });
                                  marker.addListener('mouseover', () => {
                                     infoWindow.open(beachesMap, marker);
                                  });
    
                                  marker.addListener('mouseout', () => {
                                    infoWindow.close();
                                  });

                                  marker.setMap(beachesMap);
                            }

                            //Adding pints for each beach
                            const beachPosition =  {lat:beach.location.latitude, lng:beach.location.longitude };
                            const umbrellaIcon = {
                              url: umbrella,
                              scaledSize: new window.google.maps.Size(32,35),
                            };

                            const marker = new Marker({
                              position: beachPosition,
                              beachesMap,
                              icon: umbrellaIcon,
                              optimized: true,
                            })
                            const path = beach.name.replace(/ /g, '-');
                            const tooltipContent = `<a href=\"/${path}\">${beach.name}</a>`;
                            const infoWindow = new InfoWindow({
                              content: tooltipContent,
                            });
                            marker.addListener('mouseover', () => {
                               infoWindow.open(beachesMap, marker);
                            });
    
                            marker.addListener('mouseout', () => {
                              infoWindow.close();
                            });

                           marker.setMap(beachesMap);

                        });


                    })
                    .catch((e) => {
                        // do something
                    });


                })
                .catch((e) => {
                        // do something
                });


          });


 
    }, []);   

    return (
    <>
    <div className="map-shape " id="beachesmap" style={{ width: '100%', height: '100vh' }}></div>
    </>
    );

}


export default BeachMaps;