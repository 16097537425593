import React from "react";

const CarouselItem = ({ item }) => {


    return (
        <div className="carousel-item"> 
            <div className="carousel-item-text">
                <a href={item.title}>
                <img className="carousel-img" src={item.icon}/>
                {item.title}
                </a>
            </div>
        </div>
    );
}

export default CarouselItem;