import React from "react";
import Carousel from "./Carousel";
import Hero from "./Hero";

const BottomNav = () => {

    return  (
        <div>
        <section className="bottomnav-box">
                <div className="bottomnav-row  ">
                    <div className="bottomnav-column-full">
                        <Carousel/>
                    </div>
               </div>
        </section>

        <section className="bottomnav-box">
                <div className="bottomnav-row">
                    <div className="bottomnav-column">
                        <h2>Left Nave section</h2>
                    </div>
                    <div className="bottomnav-column">
                            <img src="icon/icon-starter.png"/>
                    </div>
                    <div className="bottomnav-column">
                        <h2>Right Nave section</h2>
                    </div>
               </div>
        </section>

        </div>
    );
}

export default BottomNav;